<template>
  <a-modal
    title="角色编辑"
    :width="900"
    :keyboard="false"
    :maskClosable="false"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item style="display: none;" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-input v-decorator="['id']" />
        </a-form-item>

        <a-form-item label="角色名" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-input
            placeholder="请输入角色名"
            v-decorator="['name', { rules: [{ required: true, message: '请输入角色名！' }] }]"
          />
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="排序" has-feedback>
          <a-input-number
            style="width: 100%"
            placeholder="请输入排序"
            v-decorator="['sort', { initialValue: 100 }]"
            :min="1"
            :max="1000"
          />
        </a-form-item>

        <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-textarea :rows="4" placeholder="请输入备注" v-decorator="['remark']"></a-textarea>
        </a-form-item>

        <a-form-item label="分配员工" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <tree ref="myTree" @getTreeData="getTreeData"></tree>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { sysRoleEdit } from '@/api/modular/system/roleManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'

import tree from '@/components/Tree/selectPerson1.vue' 
export default {
  data() {
    return {
      labelCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 5
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 15
        }
      },
      visible: false,
      confirmLoading: false,
      typeEnumDataDropDown: [],
      form: this.$form.createForm(this),
      treeList: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      checkedPersonList: [], // 右侧已选择人员列表
      propsListData: [], //listData父组件传过来的
      isView: false,
      loading: false,
      id: null
    }
  },
  created() {
    this.sysDictTypeDropDown()
  },
  components: {
    tree
  },
  methods: {
    getTreeData(arr) {
      let newarr = arr.map(ele => {
        return {
          id: ele.id,
          name: ele.name,
          orgId: ele.orgId
        }
      })
      this.checkedPersonList = newarr
    },

    // 初始化方法
    edit(record) {
      this.visible = true
      this.id = record.id

      this.$nextTick(() => {
        this.form.setFieldsValue({
          id: record.id,
          name: record.name,
          sort: record.sort,
          remark: record.remark
        })
        this.$refs.myTree.add(this.id)
      })
    },
    /**
     * 获取字典数据
     */
    sysDictTypeDropDown(text) {
      sysDictTypeDropDown({
        code: 'role_type'
      }).then(res => {
        this.typeEnumDataDropDown = res.data
      })
    },
    handleSubmit() {
      const {
        form: { validateFields }
      } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        let subData = { ...values, users: this.checkedPersonList }
        console.log(subData, 'value21111111111')
        // return
        if (!errors) {
          sysRoleEdit(subData)
            .then(res => {
              if (res.success) {
                this.$message.success('编辑成功')
                this.visible = false
                this.confirmLoading = false
                this.$emit('ok', values)
                this.form.resetFields()
                this.$refs.myTree.btnClose()
              } else {
                this.$message.error('编辑失败：' + res.message)
              }
            })
            .finally(res => {
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.form.resetFields()
      this.$refs.myTree.btnClose()
      this.visible = false
    }
  }
}
</script>
